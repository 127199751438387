import React, { Component } from 'react';
import styled from 'styled-components';

import Layout from '../components/layouts';
import { Container } from '../components/styledComponents';

const StyledContainer = styled(Container)`
  .response-window {
    margin: 20px auto;
    box-shadow: none;
  }
`;

const Title = styled.h1`
  margin-bottom: 60px;
  @media (max-width: 991px) {
    margin-bottom: 25px;
  }
`;

const ContactData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  div {
    display: flex;
    align-items: center;
    color: #2d2d2d;
    font-size: 18px;
    font-family: montserrat-bold;
    img {
      margin-right: 24px;
    }
  }
  a {
    color: #2d2d2d;
  }
`;

export default class Contacts extends Component {
  render() {
    return (
      <Layout>
        <StyledContainer>
          <Title>Страница не найдена</Title>
          <p style={{ minHeight: '400px' }}></p>

          <ContactData>
            <div>
              <img alt="Адрес" src="/static/images/location.png" />
              Санкт-петербург
              <br />
              ул. Радищева, д. 39, литера Д, офис 312
            </div>
            <div>
              <img alt="Телефон" src="/static/images/phone.png" />
              <a href="tel:+78123093645">7-812-309-36-45</a>
            </div>
            <div>
              <img alt="email" src="/static/images/mail.png" />
              <a href="mailto:office@cursusts.com">office@cursusts.com</a>
            </div>
          </ContactData>
        </StyledContainer>
      </Layout>
    );
  }
}
